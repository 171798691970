import React, { useState, useEffect } from 'react';
import { db } from '../firebase/firestore';
import { getDoc, doc, updateDoc, deleteDoc, query, where, getDocs, orderBy, limit, collection } from "firebase/firestore";

const UserManagementPanel = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [user, setUser] = useState(null);
  const [editUserData, setEditUserData] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [users, setUsers] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState({});
  
  // Filters state
  const [levelFilter, setLevelFilter] = useState('');
  const [pphFilter, setPphFilter] = useState('');
  const [tokenFilter, setTokenFilter] = useState('');
  const [lastLoginFilter, setLastLoginFilter] = useState('');

  // Search state
  const [searchResults, setSearchResults] = useState([]);
  
  const fetchUsers = async (additionalFilters = {}) => {
    setLoading(true);
    try {
      const usersRef = collection(db, "telegramUsers");
  
      // Base query
      let usersQuery = query(usersRef, orderBy("balance", "desc"), limit(50));
  
      // Apply filters dynamically
      if (levelFilter) usersQuery = query(usersQuery, where("level.name", "==", levelFilter));
      if (pphFilter) usersQuery = query(usersQuery, where("profitHour", "==", pphFilter));
  
      // Handle token balance filter as a numeric value
      if (tokenFilter) {
        const tokenNumber = Number(tokenFilter);
        if (!isNaN(tokenNumber)) {
          usersQuery = query(usersQuery, where("balance", "==", tokenNumber)); // or range query
        } else {
          setErrorMessage('Invalid token value');
          return;
        }
      }
  
      if (lastLoginFilter) usersQuery = query(usersQuery, where("lastLogin", "==", lastLoginFilter));
  
      const userSnapshot = await getDocs(usersQuery);
      const lastVisibleDoc = userSnapshot.docs[userSnapshot.docs.length - 1];
      setLastVisible(lastVisibleDoc);
  
      const fetchedUsers = userSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  
      setUsers(fetchedUsers);
    } catch (error) {
      console.error("Error fetching users: ", error);
      setErrorMessage('Error fetching users');
    } finally {
      setLoading(false);
    }
  };
  

  // Handle search by username or userId
  const handleSearch = async () => {
    try {
      let userFound = false;

      // Search by username
      const usernameQuery = query(
        collection(db, "telegramUsers"),
        where("username", "==", searchTerm)
      );
      const usernameSnapshot = await getDocs(usernameQuery);
      if (!usernameSnapshot.empty) {
        const userData = usernameSnapshot.docs[0].data();
        setSearchResults([{ id: usernameSnapshot.docs[0].id, ...userData }]);
        userFound = true;
      }

      // Search by userId if not found by username
      if (!userFound) {
        const userDoc = await getDoc(doc(db, "telegramUsers", searchTerm));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setSearchResults([{ id: userDoc.id, ...userData }]);
          userFound = true;
        }
      }

      if (!userFound) {
        setSearchResults([]);
        setErrorMessage('No user found');
      } else {
        setErrorMessage('');
      }
    } catch (error) {
      console.error("Error searching user: ", error);
      setErrorMessage('Error searching user');
    }
  };

  // Handle input change for edit form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditUserData({
      ...editUserData,
      [name]: name === 'balance' || name === 'tapBalance' ? Number(value) : value
    });
  };

  // Update user data
  const handleUpdateUser = async () => {
    try {
      const userDoc = doc(db, "telegramUsers", user.id);
      await updateDoc(userDoc, editUserData);
      setSuccessMessage('User successfully updated!');
      setUser({ id: user.id, ...editUserData });
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating user: ", error);
      setErrorMessage('Error updating user');
    }
  };

  // Delete user
  const handleDeleteUser = async (userId) => {
    try {
      const userDoc = doc(db, "telegramUsers", userId);
      await deleteDoc(userDoc);
      setUsers(users.filter(user => user.id !== userId));
      setSuccessMessage('User successfully deleted!');
    } catch (error) {
      console.error("Error deleting user: ", error);
      setErrorMessage('Error deleting user');
    }
  };

  // Filter changes (called separately from search)
  useEffect(() => {
    fetchUsers();
  }, [levelFilter, pphFilter, tokenFilter, lastLoginFilter]); // Re-fetch users whenever filters change

  const toggleDropdown = (userId) => {
    setDropdownVisible(prevState => ({
      ...prevState,
      [userId]: !prevState[userId]
    }));
  };

  const formatNumber = (num) => {
    if (num < 100000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else if (num < 1000000) {
      return new Intl.NumberFormat().format(num).replace(/,/g, " ");
    } else {
      return (num / 1000000).toFixed(3).replace(".", ".") + " M";
    }
  };

  return (
    <div className="w-full flex flex-col space-y-4 h-[100vh] scroller pt-4 overflow-y-auto pb-[150px]">
      {/* Search input */}
      <div className="w-full sm:w-[50%] flex flex-col gap-3">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search by username or userId"
          className="bg-[#4b4b4b] w-full placeholder:text-[#b9b9b9] text-[#e0e0e0] placeholder:text-[12px] text-[13px] placeholder:font-light h-[55px] border-none outline-none rounded-[10px] flex items-center px-6"
        />
        <button onClick={handleSearch} className="bg-[#17B502] font-semibold text-[15px] rounded-[6px] w-full sm:w-[200px] h-fit px-4 py-3 text-[#000]">Search</button>
        {errorMessage && <p className="text-red-500">{errorMessage}</p>}
        {successMessage && <p className="text-green-500">{successMessage}</p>}

        {/* Filter Options */}
        <div className="flex gap-3 mt-3">
          <select value={levelFilter} onChange={(e) => setLevelFilter(e.target.value)} className="bg-[#4b4b4b] text-[#e0e0e0] rounded-[10px] px-4 py-2">
            <option value="">Select Level</option>
            <option value="Beginner">Beginner</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Advanced">Advanced</option>
          </select>
          <select value={pphFilter} onChange={(e) => setPphFilter(e.target.value)} className="bg-[#4b4b4b] text-[#e0e0e0] rounded-[10px] px-4 py-2">
            <option value="">Select PPH</option>
            <option value="10000">10000</option>
            <option value="20000">20000</option>
            <option value="1110000">1110000</option>
          </select>
          <select value={tokenFilter} onChange={(e) => setTokenFilter(e.target.value)} className="bg-[#4b4b4b] text-[#e0e0e0] rounded-[10px] px-4 py-2">
            <option value="">Select Tokens</option>
            <option value="100">100 Tokens</option>
            <option value="50000">50000 Tokens</option>
            <option value="1000000">1000000 Tokens</option>
          </select>
          <select value={lastLoginFilter} onChange={(e) => setLastLoginFilter(e.target.value)} className="bg-[#4b4b4b] text-[#e0e0e0] rounded-[10px] px-4 py-2">
            <option value="">Last Login</option>
            <option value="today">Today</option>
            <option value="lastWeek">Last Week</option>
            <option value="lastMonth">Last Month</option>
          </select>
        </div>

        {/* Search Results */}
        <div>
          {searchResults.length > 0 && (
            <div className='w-full flex flex-col space-y-3 bg-cards p-4 rounded-[10px] text-[13px]'>
              <p><strong>Username:</strong> {searchResults[0].username}</p>
              <p><strong>User Id:</strong> {searchResults[0].id}</p>
              <p><strong>User balance:</strong> {formatNumber(searchResults[0].balance)}</p>
            </div>
          )}
        </div>
      </div>

      {/* Users List */}
      <div className="w-full sm:w-[50%] flex flex-col space-y-3">
        <h2 className="text-[20px] font-semibold">Users List</h2>
        {users.map((user, index) => (
          <div key={user.id} className="bg-[#4b4b4b] p-4 rounded-[10px] text-[13px] relative flex flex-col w-full space-y-2">
            <span className='flex w-full items-center space-x-1'>
              <span className='w-[16px] h-[16px] flex justify-center items-center rounded-full bg-cards3'>
                <strong>{index + 1}</strong>
              </span>
              <span className='line-clamp-1 font-semibold'>{user.username} | {user.id}</span>
            </span>
            <span className='flex items-center gap-1'>
              <img src='/coin.webp' alt="balance" className="w-[14px] h-[14px] rounded-full" />
              <p><span className='font-semibold text-accent'>{formatNumber(user.balance)}</span></p>
            </span>
            <span className='flex items-center gap-1'>
              <img src={user.level.imgUrl} alt="User Level" className="w-[14px] rounded-full h-[14px]" />
              <p className='text-secondary'>{user.level.name} level</p>
            </span>

            <button onClick={() => toggleDropdown(user.id)} className="absolute top-2 right-2 bg-gray-700 text-white rounded-full p-2 h-[28px] w-[28px] flex items-center justify-center">
              ⋮
            </button>

            {dropdownVisible[user.id] && (
              <div className="absolute z-10 top-8 right-2 bg-[#2e2e2e] text-primary rounded-md shadow-lg w-40">
                <button onClick={() => {
                  setUser(user);
                  setEditUserData(user);
                  setIsEditing(true);
                }} className="w-full p-2 text-left hover:bg-[#1e1e1e]">Edit</button>
                <button onClick={() => handleDeleteUser(user.id)} className="w-full p-2 text-left hover:bg-[#1e1e1e]">Delete</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserManagementPanel;
