import React, { useEffect, useState, useRef } from 'react';
import { useUser } from '../context/userContext';
import { NavLink } from 'react-router-dom';
import { db } from '../firebase/firestore';
import { collection, getDocs } from "firebase/firestore";
import { RiSettings4Fill } from 'react-icons/ri';

// Components
import Levels from '../Components/Levels';
import SettingsMenu from '../Components/SettingsMenu';
import Exchanges from '../Components/Exchanges';
import BalanceInfo from '../Components/BalanceInfo';

const Events = () => {
  const { fullName, balance, refBonus, selectedCharacter, selectedExchange } = useUser();

  const [showExchange, setShowExchange] = useState(false);
  const [info, setInfo] = useState(false);
  const [showLevel, setShowLevel] = useState(false);
  const [showSetting, setShowSetting] = useState(false);
  const [events, setEvents] = useState([]);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);

  const scrollRef = useRef(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "gameEvents"));
        const eventsList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setEvents(eventsList);
      } catch (error) {
        console.error("Error fetching game events: ", error);
      }
    };
    fetchEvents();
  }, []);

  const handleNextEvent = () => {
    if (currentEventIndex < events.length - 1) {
      setCurrentEventIndex(prevIndex => prevIndex + 1);
    }
  };

  const handlePrevEvent = () => {
    if (currentEventIndex > 0) {
      setCurrentEventIndex(prevIndex => prevIndex - 1);
    }
  };

  const calculateCountdown = (endDate) => {
    const now = new Date();
    const end = new Date(endDate);
    const timeLeft = end - now;

    if (timeLeft <= 0) return null;

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    return { days, hours };
  };

    // Render UI for each event
    const renderLeaderboard = (leaderboard) => {
      if (!leaderboard) return <p className="text-gray-400 text-center text-[14px]">Leaderboard data is not available yet.</p>;
  
      return (
        <div className="overflow-y-auto max-h-[400px] mt-2 bg-gray-700 rounded-lg p-2">
          <ul className="text-gray-400 text-[14px]">
            {Object.entries(leaderboard)
              .slice(0, 100) // Limit to the top 100 players
              .map(([userId, player], index) => (
                <li key={userId} className="flex justify-between py-2 border-b border-gray-600">
                  <span>{index + 1}. {player.name}</span>
                  <span>{player.points} pts</span>
                </li>
              ))}
          </ul>
        </div>
      );
    };

  const renderEvent = (event) => {
    const now = new Date();
    const isExpired = new Date(event.endDate) < now;
    const countdown = isExpired ? null : calculateCountdown(event.endDate);

    return (
      <div className="relative bg-gray-800 p-6 rounded-lg shadow-lg mb-6 transition-all transform hover:scale-105 w-full max-w-md mx-auto">
        <div className="flex justify-center mb-4">
          <img
            src={event.icon}
            alt={event.name}
            className="w-24 h-24 object-cover rounded-full shadow-lg"
          />
        </div>

        <h2 className="text-white text-[20px] font-bold mb-2 text-center">{event.name}</h2>
        <p className="text-gray-300 text-[14px] mb-4 text-center">{event.description}</p>
        <p className="text-yellow-400 text-[14px] font-semibold text-center mb-4">Rewards: {event.rewards}</p>

        {isExpired && (
          <span className="absolute top-4 left-4 bg-red-600 text-white text-xs font-bold px-2 py-1 rounded">Expired</span>
        )}

        {!isExpired && countdown && (
          <div className="text-center text-gray-400 text-sm">
            <p>{`Time Remaining: ${countdown.days} days ${countdown.hours} hours`}</p>
          </div>
        )}

        <div className="mt-6">
          <NavLink
            to={`${event.url}?GameEvent=${event.id}`}
            className={`bg-gradient-to-r from-yellow-400 to-yellow-600 text-black text-[18px] font-semibold rounded-lg mt-4 p-4 w-full text-center transition-all transform hover:scale-105 shadow-xl ${isExpired ? 'opacity-50 cursor-not-allowed' : ''}`}
            style={{ margin: '0 auto', display: 'block' }}
            onClick={(e) => isExpired && e.preventDefault()}
          >
            Play
          </NavLink>
          <div className="mt-6">
          <h3 className="text-white text-[16px] font-bold mb-2 text-center">Leaderboard (Top 100)</h3>
          {renderLeaderboard(event.leaderboard)}
        </div>
        </div>




        <div className="absolute top-1/2 left-0 right-0 flex justify-between transform -translate-y-1/2">
          <button
            onClick={handlePrevEvent}
            className="p-4 rounded-full transition shadow-lg"
            disabled={currentEventIndex === 0}
          >
            <span className="text-white text-xl">{"<"}</span>
          </button>
          <button
            onClick={handleNextEvent}
            className="p-4 rounded-full transition shadow-lg"
            disabled={currentEventIndex === events.length - 1}
          >
            <span className="text-white text-xl">{">"}</span>
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className="overflow-y-auto h-screen scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-800"
        ref={scrollRef}
        style={{ marginTop: '-12px' }}
      >
        <div className="flex justify-between bg-[#1a1a1a] p-4 items-center bg-gray-800 bg-opacity-50 p-3 rounded-bl-[22px] rounded-br-[22px] shadow-lg">
          <div>
            <div className="flex items-center space-x-3">
              <div className="relative flex items-center justify-center overflow-hidden">
                <img
                  src={selectedCharacter.avatar}
                  className="w-12 h-12 rounded-full border-4 border-yellow-400 shadow-lg"
                  alt={fullName || 'user'}
                />
                <span className="absolute bottom-0 right-0 bg-green-500 w-4 h-4 rounded-full border-2 border-white"></span>
              </div>
              <div>
                <h1 className="text-lg font-bold text-white">{fullName}</h1>
                <span className="text-sm text-yellow-400 font-semibold">FM Guardian</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-end">
            <div className="flex items-center space-x-4">
              <button
                onClick={() => setShowExchange(true)}
                className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition shadow-lg"
              >
                <img
                  id={selectedExchange.id}
                  src={selectedExchange.icon}
                  alt={selectedExchange.name}
                  className="w-6 h-6"
                />
              </button>
              <button
                onClick={() => setShowSetting(true)}
                className="p-2 bg-gray-700 rounded-full hover:bg-gray-600 transition shadow-lg"
              >
                <RiSettings4Fill size={24} className="text-white" />
              </button>
            </div>
            <div
              className="w-fit py-[2px] px-3 flex items-center space-x-1 justify-center border-[1px] border-[#707070] rounded-[25px]"
              style={{ marginTop: '18px' }}
            >
              <span className="w-[14px]">
                <img alt="engy" src="/loader.webp" className="w-full" />
              </span>
              <h1 className="text-[15px] font-bold">{balance + refBonus}</h1>
            </div>
          </div>
        </div>

        <h1 className="text-center text-[24px] mt-[18px] text-white font-bold">Game Events</h1>

        <div className="relative overflow-hidden h-[750px] scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-800 p-4 rounded-lg shadow-lg">
          {events.length > 0 ? renderEvent(events[currentEventIndex]) : <p className="text-center text-gray-400">Loading events...</p>}
        </div>
      </div>

      <Levels showLevel={showLevel} setShowLevel={setShowLevel} />
      <Exchanges showExchange={showExchange} setShowExchange={setShowExchange} />
      <SettingsMenu showSetting={showSetting} setShowSetting={setShowSetting} />
      <BalanceInfo info={info} setInfo={setInfo} />
    </>
  );
};

export default Events;
